<template>
    <b-row class="no-gutters">
        <b-col cols="6">
            <b-form-group>
                <b-form-datepicker 
                    :date-format-options="{ weekday:'short', day:'numeric', month: 'short', year: 'numeric' }"
                    locale="nl"
                    :min="startMin"
                    :hide-header="true"
                    v-model="value.start"
                    ref="start"
                    @input="changeStart"
                    boundary="viewport"
                    placeholder="---"
                    class="dp-left"
                >
                    <template slot="button-content">
                        <font-awesome-icon icon="calendar" />
                    </template>
                </b-form-datepicker>
            </b-form-group>
        </b-col>
        <b-col cols="6">
            <b-form-group>
                <b-form-datepicker 
                    :date-format-options="{ weekday:'short', day: 'numeric', month: 'short', year: 'numeric' }"
                    locale="nl"
                    :min="endMin"
                    :hide-header="true"
                    v-model="value.end"
                    ref="end"
                    @input="changeEnd"
                    boundary="viewport"
                    placeholder="---"
                    class="dp-right"
                >
                    <template slot="button-content">
                        <font-awesome-icon icon="calendar" />
                    </template>
                </b-form-datepicker>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'DatePicker',
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        methods: {
            changeStart: function(val) {
                if (this.value.end) {
                    this.endMin = moment(val).add(1, 'week').toDate();
                    this.value.end = moment(val).add(1, 'week').format('YYYY-MM-DD');
                    this.$refs.end.$el.querySelector('button').click();
                }

                this.$emit('change', this.value);
            },
            changeEnd: function() {
                this.$emit('change', this.value);
            },
        },
        data() {
            return {
                startMin: null,
                endMin: moment().add(1, 'day').toDate(),
            }
        },
    }
</script>

<style scoped>
    @media screen and (min-width:991px) {
        .dp-left{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .dp-right{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .dp-left{
        border-top-left-radius:0.25rem;
        border-bottom-left-radius:0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .dp-right{
        border-top-right-radius:0.25rem;
        border-bottom-right-radius:0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    fieldset{
        margin-bottom:0;
    }
</style>