<template>
  <div>
    <b-card class="mb-4 bg-light">
      <b-row>
        <b-col lg="6" class="mb-3 mb-lg-0">
          <vue-bootstrap-autocomplete
              :data="results"
              v-model="result"
              :serializer="s => s.event.name + (s.location ? s.location.name : null)"
              placeholder="Zoeken..."
              @input="getResults"
              @hit="selectedResult"
          >
            <template slot="prepend">
              <span class="input-group-text bg-white">
                <font-awesome-icon icon="search" />
              </span>
            </template>
            <template slot="suggestion" slot-scope="{ data }">
              <div>
                <small v-if="data.event.is_end_ticket_sale || data.event.is_sold_out" class="d-block text-primary">
                  Uitverkocht
                </small>
                <div class="d-block text-primary">
                  {{ data.start_at|formatDateTime }}
                </div>
                <div class="text-dark">
                  {{ data.event.name }}
                </div>
                <small v-if="data.location" class="d-block text-primary">
                  {{ data.location.name }}
                </small>
              </div>
            </template>
          </vue-bootstrap-autocomplete>
        </b-col>
        <b-col lg="6">
          <DatePicker :value="filters.range" v-model="filters.range" @change="changeFilter" />
        </b-col>
      </b-row>
    </b-card>

    <template v-if="!slotPerDate.length">
      <b-alert variant="info" v-if="!isLoading" show>
        Er zijn geen evenementen gevonden. Gebruik het filter om verder te zoeken.
      </b-alert>

      <div class="text-center" v-if="isLoading">
        <b-spinner variant="primary" class="mt-2 mb-2" label="Spinning"></b-spinner>
      </div>
    </template>

    <template v-if="slotPerDate.length">
      <div class="text-muted text-right mb-3">
        <small>Aantal resultaten: {{ schedule.length }}</small>
      </div>
      <b-row>
        <b-col lg="5" class="mb-3">
          <div>
            <b-form-select v-model="filters.location" :options="getLocationOptions" @change="changeFilter"/>
          </div>
        </b-col>
        <b-col lg="7">
          <b-button-group v-if="slotPerDate.length > 1" class="filter flex-wrap w-100 mb-3">
            <b-button class="outline-button mb-1" @click="goToSection('range_' + key)" variant="outline-primary" v-for="(item, key) in slotPerDate" :key="key">
              {{ item.date|formatTextDate }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </template>

    <section v-for="(item, key) in slotPerDate" :key="key" :ref="'range_' + key">
      <h3 class="sticky-top text-center text-md-left text-white bg-primary p-2 pl-3 pr-3 mt-0 mb-3">{{ item.date|formatTextDate }}</h3>
      <div class="masonry">
        <b-card class="mb-3 overflow-hidden masonry-item cursor-pointer border-bottom" :class="slot.event.is_highlighted && !slot.event.canceled_at ? 'highlighted' : null" v-for="(slot, key) in item.slots" :key="key" no-body>
          <template v-if="slot.event.header_image && slot.show_image">
            <b-card-img-lazy :src="'https://api.tixgo.nl/files/' + slot.event.header_image + '/950'" :alt="slot.event.name" top />
          </template>
          <b-card-body>
            <template v-if="!slot.event.canceled_at">
              <template v-if="slot.event.is_highlighted">
                <div class="card-shape-text">
                  Uitgelicht							
                </div>
              </template>
            </template>
            <div class="h4 text-primary">
              {{ slot.start_at|formatTime }}
            </div>
            <h4 class="h5">{{ slot.event.name }}</h4>
            <div v-if="slot.location" class="fs-6 text-muted mb-2">
              <small>
                <strong>{{ slot.location.name }}</strong> - {{ slot.location.place }}
              </small>
            </div>
            <p v-if="slot.event.description" class="text-muted mb-2" v-html="$options.filters.truncate(slot.event.description, 150)"></p>
            <template v-if="slot.event.canceled_at">
              <b-badge variant="danger" class="mb-2">
                Geannuleerd
              </b-badge>
            </template>
            <template v-else>
              <b-badge variant="primary" class="mb-2" v-if="slot.event.is_nearly_sold_out && !slot.event.is_sold_out">
                Nog enkele tickets beschikbaar
                <font-awesome-icon icon="angle-double-right" />
              </b-badge>
              <b-badge variant="dark" class="mb-2" v-else-if="slot.event.is_end_ticket_sale || slot.event.is_sold_out">
                Uitverkocht
              </b-badge>
            </template>
            <router-link class="stretched-link text-primary text-decoration-none d-block" :target="target" :to="path + slot.event.slug + '-' + slot.event.slug_hash + '/' + slot.slug">
              <small>
                <template v-if="slot.event.canceled_at || slot.event.is_end_ticket_sale || slot.event.is_sold_out">
                  Meer details
                  <font-awesome-icon icon="angle-double-right" />
                </template>
                <template v-else>
                  Tickets bestellen
                  <font-awesome-icon icon="angle-double-right" />
                </template>
              </small>
            </router-link>
          </b-card-body>
        </b-card>
      </div>
    </section>
  </div>
</template>

<script>
  import moment from 'moment'
  import VueBootstrapAutocomplete from '@vue-bootstrap-components/vue-bootstrap-autocomplete';
  import DatePicker from '@/shared/components/DatePicker.vue';
  
  export default {
    components: {
      DatePicker,
      VueBootstrapAutocomplete,
    },
    props: {
      target: {
          default: '_blank',
          type: String
      },
      path: {
        default: '/event/',
        type: String
      }
    },
    data() {
        return {
          isLoading: false,
          results: [],
          result: '',
          schedule: [],
          filters: {
            location: 'any',
            range: {
              start: moment().format('YYYY-MM-DD'),
              end: null,
            },
          }
        }
    },
    computed: {
      slotPerDate() {
        let array = [];

        if (this.schedule && this.schedule.length > 0) {

          let result = [];

          this.schedule.forEach(slot => {

            if (slot.event.is_highlighted) {
              slot.show_image = true;
            } else {
              slot.show_image = this.isRandomShow();
            }

            let date = moment(slot.start_at).format('YYYY-MM-DD');

            if (result[date] != undefined) {
              result[date].slots.push(slot);
            } else {
              result[date] = { date: date, slots: [slot] };
            }
          });

          for (let i in result) {
            array.push(result[i]);
          }
        }

        return array;
      },
      getLocationOptions() {
        let all = { text: 'Alle locaties', value: 'any' };
        let array = [];

        if (this.schedule && this.schedule.length > 0) {

          let result = [];

          this.schedule.forEach(item => {
            if (item.location) {
              result[item.location.slug_hash] = { text: item.location.name, value: item.location.slug_hash };
            }
          });

          for (let i in result) {
            array.push(result[i]);
          }
        }

        array.sort((a, b) => a.text.localeCompare(b.text));

        return [all, ...array];
      }
    },
    methods: {
      selectedResult: function(slot) {
        if (this.target == '_blank') {
          window.open(this.path + slot.event.slug + '-' + slot.event.slug_hash, '_blank');
        } else {
          this.$router.push(this.path + slot.event.slug + '-' + slot.event.slug_hash);
        }
      },
      isRandomShow() {
        return Math.random() < 0.6;
      },
      changeFilter() {
        this.setRouter();
        this.fetchSchedule();
      },
      async getResults(search) {

        let params = {};

        if (search) {
          params.search = search;
        }

        if (this.filters.range.start) {
          params.start = this.filters.range.start;
        }

        if (this.filters.range.end) {
          params.end = this.filters.range.end;
        }

        let query = new URLSearchParams(params);

        await this.$axios.get("https://api.tixgo.nl/schedule/" + this.$route.params.host + '?' + query.toString())
          .then( response => {
            if (response.data) {
              this.results = response.data;
            }
          }
        ).catch((reason) => {
            console.log(reason);
        });
      },
      fetchSchedule() {
        this.schedule = [];
        this.isLoading = true;

        let params = {};

        if (this.filters.location) {
          params.location = this.filters.location;
        }

        if (this.filters.range.start) {
          params.start = this.filters.range.start;
        }

        if (this.filters.range.end) {
          params.end = this.filters.range.end;
        }

        let query = new URLSearchParams(params);

        this.$axios.get("https://api.tixgo.nl/schedule/" + this.$route.params.host + '?' + query.toString())
            .then( response => {
              this.schedule = response.data;
              this.isLoading = false;
            }
        ).catch((reason) => {
            reason;
        });
      },
      goToSection(value) {
        const top = this.$refs[value][0];
        
        top.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      },
      setRouter() {
        let object = {};

        if (this.filters.location) {
          object.location = this.filters.location;
        }
        
        if (this.filters.range.start) {
          object.start = this.filters.range.start;
        }

        if (this.filters.range.end) {
          object.end = this.filters.range.end;
        }

        this.$router.push({ query: object });
      }
    },
    created() {
      if (this.$route.query.location) {
        this.filters.location = this.$route.query.location;
      }

      if (this.$route.query.start) {
        this.filters.range.start = this.$route.query.start;
      }

      if (this.$route.query.end) {
        this.filters.range.end = this.$route.query.end;
      }

      this.fetchSchedule();
    }
  }
</script>

<style scoped>
/* Masonry grid */
.masonry {
  column-gap: 1rem;
  column-fill: initial;
  column-count: 1;
}

/* Masonry item */
.masonry .masonry-item {
  width:100%;
  display: inline-block; /* Fix the misalignment of items */
  vertical-align: top; /* Keep the item on the very top */
}

.masonry .masonry-item:hover{
  background-color:#f8f9fa;
}

/* Masonry image effects */
.masonry .masonry-item img {
  object-fit:cover;
  width:100%;
  height:200px;
  transition: all .5s ease-in-out;
  backface-visibility: hidden; /* Remove Image flickering on hover */
}

.masonry .masonry-item:hover img {
  opacity: .75;
}

@media screen and (max-width: 767px) {
  .masonry .masonry-item {
    text-align:center;
  }
}

/* Masonry on tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .masonry {
    column-count: 2;
  }
}

/* Masonry on big screens */
@media only screen and (min-width: 1024px) {
  .masonry {
    column-count: 3;
  }
}
.highlighted{
  border: 2px solid var(--primary-color) !important;
}

.card-shape{    
}
.card-shape-text{
  color:#fff;
  font-size:14px;
  white-space: nowrap;
	transform:rotate(46deg);
  background-color:var(--primary-color);
  padding:5px 40px;
  position:absolute;
  top: 20px;
  right: -35px;
}
</style>